@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('../node_modules/open-iconic/font/css/open-iconic-bootstrap.css');


html, body { height: 100%;
  }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;overflow-y: scroll; }

.mat-tab-body-content{
padding-top: 10px;

}
.mat-form-field{

  max-width: 555px;
  padding-right:40px;

}

.mat-card-header-text {
   margin: 0 0px !important; 
}
.container{
  margin-top:20px;
}
@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 98%;
}
}

.containerWide{
  max-width: 100%;
}




.mat-raised-button {
  margin-right: 15px !important;
}
.full-width {
  width: 100%;
}
.cardlink{
cursor: pointer;

}



.card {
    min-width: 330px;
    max-width: 530px;
    margin:10px;
    float: left;
  }
  .cardfull {
    min-width: 100%;
    margin:10px;
    float: left;
  }
  .material-icons {
    font-size: 30px;
    color: #238bf7c7;
  }
  .red{
    color: red;
  }
  .largeicon{
    font-size: 40px;
  }
  
  button.mat-menu-item {
    padding-top: 8px;
}
  .buttonlabel{
    position: relative;
    top: -10px;
    left: 10px;
    padding-right: 20px;
    margin-right: 11px;
    display: inline-block;
  }

  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: 4px !important;
    right: -7px !important;
}

  .mat-card-avatar{
        width:60px !important;
  }
  .row{
  margin-right: 0px;
}

/* table Structure */
.table-container {
    position: relative;
    min-height: 200px;
  }
  
  .table-table-container {
    position: relative;
    max-height: 400px;
    overflow: auto;
  }
  
  table {
    width: 100%;
  }
  
  .table-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .table-rate-limit-reached {
    color: #980000;
    max-width: 360px;
    text-align: center;
  }
  
  /* Column Widths */
  .mat-column-number,
  .mat-column-state {
    max-width: 64px;
  }
  
  .mat-column-created {
    max-width: 124px;
  }
  

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.HeadingMain{
  font-size: 48px;
  font-weight: bold;

}
.Heading{
  color: #3f51b5;
  font-size: 36px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1;
  
  }

.Heading3{
    font-size: 18px;

}
